.products__container{
    /* grid-template-columns: repeat(auto-fit, minmax(218px, 1fr)); */
    grid-template-columns: repeat(3, 300px);
    justify-content: center;
    align-items: center;
    column-gap: 1.8rem;
    margin-top: var(--mb-2);
}

.products__content {
    /* position: relative; */
    /* display: grid; */
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--container-color);
    padding: 1rem;
    border-radius: 0.75rem;
}

.products__info{
    display: grid;
    row-gap: 1rem;
    /* grid-template-columns: 1fr; */
}


.products__title{
    font-size: var(--h3-font-size);
    margin-top: var(--mb-1);
    /* margin-bottom: var(--mb-1); */
    font-weight: var(--font-medium);
}

.product__desc{
    margin-bottom: var(--mb-1);
    font-size: 12px;
    font-style: italic;
}

.products__button{
    color: var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
}

.product__note-title{
    text-align: center;
    margin-top: 5px;
    width: auto;
    font-size: 14px;
    justify-self: center;
}

.products__button-icon{
    font-size: 1rem;
    transition: 0.3s;
}

.products__button:hover .products__button-icon{
    transform: translateX(000.25rem);
}

.products__modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.products__modal-content{
    width: 500px;
    position: relative;
    background-color: var(--container-color);
    padding: 4.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
}

.products__modal-close{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}

.products__modal-title,
.products__modal-description {
    text-align: center;
}

.products__modal-title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}

.products__image {
    width: 100%; /* Ensure images fill container width */
    height: 200px; /* Set desired image height */
    object-fit: cover; /* Maintain aspect ratio and crop if necessary */
    border-radius: 0.75rem; /* Match content box corner radius */
  }

.products__modal-description {
    font-size: var(--small-font-size);
    padding: 0 3.5rem;
    margin-bottom: var(--mb-2);
}

.products__modal-products{
    row-gap: 0.75rem;
}

.products__modal-product{
    display: flex;
    align-items: center;
    column-gap: .5rem;
}

.products__modal-icon{
    color: var(--title-color);
    font-size: 1.1rem;
}

.products__modal-info{
    font-size: var(--small-font-size);
}

/* Active modal*/
.active-modal {
    opacity: 1;
    visibility: visible;
}

@media screen and (max-width: 992px) {
    .products__container{
        grid-template-columns: repeat(3, 230px);
        column-gap: 1.8rem;
    }

    .products__content {
        width: auto;
    }
    
}

@media screen and (max-width: 768px) {
    .products__container{
        grid-template-columns: repeat(1, 300px);
    }
    
    .products__content {
        width: auto;
    }
    
    .products__info{
        grid-template-columns: 1fr;
    }
    
}

@media screen and (max-width: 576px){
    .products__container{
        grid-template-columns: repeat(1, 218px);
    }

    .products__info{
        grid-template-columns: 1fr;
    }

    .products__content {
        width: auto;
    }


    /* .products__content{
        grid-template-columns: 1fr;
    } */

}